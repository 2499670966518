:root {
    --primary-color: #2c3e50;
    --secondary-color: #34495e;
    --accent-color: #3498db;
    --text-color: #333;
    --bg-color: #f4f4f4;
}

body, html {
    margin: 0;
    padding: 0;
    font-family: 'Noto Sans JP', sans-serif;
    line-height: 1.6;
    color: var(--text-color);
    background-color: var(--bg-color);
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

/* Header Styles */
header {
    background-color: var(--primary-color);
    color: white;
    padding: 20px 0;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

nav ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: flex-end;
}

nav ul li {
    margin-left: 20px;
}

nav ul li a {
    color: white;
    text-decoration: none;
    font-weight: 300;
    transition: color 0.3s ease;
}

nav ul li a:hover {
    color: var(--accent-color);
}

/* Hero Section Styles */
.hero {
    background-color: var(--secondary-color);
    color: white;
    text-align: center;
    padding: 100px 0;
}

.hero h1 {
    font-size: 3em;
    margin-bottom: 20px;
    font-weight: 700;
}

.hero p {
    font-size: 1.2em;
    max-width: 600px;
    margin: 0 auto;
    font-weight: 300;
}

/* Services Section Styles */
.services {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-top: 50px;
}

.service {
    flex: 1;
    margin: 0 10px 40px;
    text-align: left;
    padding: 30px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
}
.service:hover {
    transform: translateY(-5px);
}

.service i {
    font-size: 3em;
    color: var(--accent-color);
    margin-bottom: 20px;
    display: block;
    text-align: center;
}

.service h2 {
    margin-bottom: 20px;
    color: var(--primary-color);
    text-align: center;
}

.service-description {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    line-height: 1.6;
    overflow-y: auto;
    max-height: 500px;
}

.description-section {
    margin-bottom: 20px;
}

.description-section h3 {
    color: var(--secondary-color);
    margin-bottom: 10px;
    font-size: 1.1em;
}

.description-section ul {
    padding-left: 20px;
    margin: 0;
}

.description-section li {
    margin-bottom: 5px;
}

/* Staff Section Styles */
.staff {
    margin-top: 50px;
    background-color: white;
    border-radius: 10px;
    padding: 40px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.staff h2 {
    text-align: center;
    color: var(--primary-color);
    margin-bottom: 30px;
}

.staff-list {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.staff-member {
    text-align: center;
    margin-bottom: 20px;
}

.staff-member i {
    font-size: 2em;
    color: var(--accent-color);
    margin-bottom: 10px;
}

.staff-member p {
    white-space: pre-line;
  }

/* Contact and Map Section Styles */
.contact-map {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.contact-info {
    flex-basis: 45%;
    padding: 40px;
}

.map {
    flex-basis: 55%;
    height: 400px;
}

/* Footer Styles */
footer {
    background-color: var(--primary-color);
    color: white;
    text-align: center;
    padding: 20px 0;
    margin-top: 50px;
}

.image-carousel {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  
  .image-carousel img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
  }
  
  .slick-prev, .slick-next {
    z-index: 1;
  }
  
  .slick-prev {
    left: 10px;
  }
  
  .slick-next {
    right: 10px;
  }
  
  .slick-prev:before, .slick-next:before {
    color: var(--accent-color);
  }
  
  .slick-dots {
    bottom: -30px;
  }
  
  .slick-dots li button:before {
    color: var(--accent-color);
  }
  



/* Responsive Design */
@media (max-width: 1024px) {
    .services {
        flex-wrap: wrap;
    }
    .service {
        flex-basis: calc(50% - 20px);
    }
}





@media (max-width: 768px) {
    .service {
        flex-basis: 100%;
        margin-left: 0;
        margin-right: 0;
    }
    /* レスポンシブデザインの調整 */
    .image-carousel {
        margin-top: 30px;
        margin-bottom: 30px;
      }
    
}

